import { pagePaths } from 'constants/path';
import { IMDataResponse } from 'models/Common/commonApiTypes';
import { IMReportData } from 'models/Common/commonTypes';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useLoadingStore } from 'store/useLoadingStore';

export const ReportView = () => {
    const { setLoading } = useLoadingStore();
    const [report, setReport] = useState<IMDataResponse<IMReportData> | null>(null);

    const windowListener = (event: MessageEvent<any>) => {
        const origin = event.origin.toString();
        /// Todo : 필터링 되는 오리진 변경 하기
        if (!origin.includes('localhost') && origin !== 'https://mtem.tlchealthcare.co.kr') return;

        /// 윈도우에서 넘어온 데이터 파싱 및 타입 매칭
        const typeMatcher = (type: any): type is IMDataResponse<IMReportData> => {
            return type && typeof type === 'object' && 'data' in type && Array.isArray(type.data);
        };

        if (typeof event.data !== 'string') return;

        const response = JSON.parse(event.data);

        let reportData = typeMatcher(response) ? response : undefined;

        /// 데이터 파싱 실패 시 에러 메세지 전송
        if (reportData === undefined || reportData === null) {
            window.opener.postMessage(
                JSON.stringify({
                    result: 'fail',
                }),
                '*'
            );
            return;
        }

        /// 데이터 전송
        setLoading(false);
        setReport(reportData);
    };

    useEffect(() => {
        setLoading(true);
        window.addEventListener('message', windowListener);
        window.opener.postMessage(JSON.stringify({ result: 'ready' }), '*');
        return () => {
            window.removeEventListener('message', windowListener);
        };
    }, []);

    /// 리포트 파싱 성공 시 메세지 전송
    useEffect(() => {
        if (!report) return;
    }, [report]);

    if (report) return <Navigate to={pagePaths.report} state={{ response: report, type: 'mobile' }} replace />;

    return <></>;
};
