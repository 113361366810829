export namespace ChartTypes {
    export type ChartStandardtype = 'good' | 'fair' | 'poor';
    export type SubGradeType = 'min' | 'max';

    export type GradeType = {
        grade: GradeGroupType;
    };

    export interface StandardArrayType {
        index: number;
        value: number;
    }

    export type ChartInputDataType = {
        overallData: number[];
        percentile: number;
        averageData: number;
        inputData: number;
        averageStandard?: number;
        decimalCut?: number;
    };
}

export type GradeGroupType = 'Superior' | 'Excellent' | 'Good' | 'Fair' | 'Poor' | 'Very Poor';
export const AgeGroup = ['2', '3', '4', '5', '6', '7'];

export type GetPercentileFunc = (vo2Max: number, age: number, gender: 'male' | 'female') => number;

export type AgeToAgeGroupFunc = (age: number) => string;

export type GetAvgVo2MaxFromAgeFunc = (age: number, gender: 'male' | 'female') => number;

export type PercentileToGradeFunc = (percentile: number) => GradeGroupType;

export type FindMinMaxFromAgeGroupFunc = (
    ageGroup: string,
    gender: 'male' | 'female'
) => {
    min: number;
    max: number;
};

export type GetMETFunc = (vo2Max: number) => number;

export type KH6minuteSpeedFunc = (distance: number) => number;

//// 6분 걷기 검사 표
export type WalkingAssessmentTableType = {
    distance: number;
    vo2Max: number;
    vo2Average: number;
    mets: number;
    speed: number;
    bidBefore: number;
    bidAfter: number;
    hrBefore: number | string;
    hrAfter: number | string;
    spo2Before: number | string;
    spo2After: number | string;
};

export type TrademillExerciseType = {
    kilometerPerHour: number;
    kcalPerMinute: number;
};

export type OutdoorGoalTableType = {
    kilometer: number;
    minute: number;
};

export type SafezoneGuideTableType = {
    min: number;
    max: number;
};

/// 달리기 강도
export type RunningIntensityType = {
    targetBurnFatExercise: TrademillExerciseType;
    targetAerobicExercise: TrademillExerciseType;
    targetHighIntensityExercise: TrademillExerciseType;
    outdoorGoalLow: OutdoorGoalTableType;
    outdoorGoalMid: OutdoorGoalTableType;
    outdoorGoalHigh: OutdoorGoalTableType;
    metabolicSafezoneGuide: SafezoneGuideTableType;
    circulatorySafezoneGuide: SafezoneGuideTableType;
};

/// 최적화 달리기 프로그램
export type OptimizedProgramType = {
    recoveryRun: number;
    heartRateZone: {
        zone1: {
            min: number;
            max: number;
            kcalPerMinute: number;
        };
        zone2: {
            min: number;
            max: number;
            kcalPerMinute: number;
        };
    };
    highIntensityRun: {
        burnFat: {
            kilometerPerHour: number;
            kcalPerMinute: number;
        };
        highIntensity: {
            kilometerPerHour: number;
            kcalPerMinute: number;
        };
        repetition: {
            min: number;
            max: number;
        };
    };
};

///cardiorespiratoryAge : 심페나이
///vo2MaxAvg : VO2Max 평균치
/// vo2Max : VO2Max
/// 진단
export type Vo2MaxDiagnosisType = {
    cardiorespiratoryAge: number;
    memo?: string;
    totalScoreResult: GradeGroupType;
    vo2MaxPercentile: number;
    vo2Max: number;
};

/// 평균치 비교
export type WalkingSpeedAverageType = {
    speedChartProps: ChartTypes.ChartInputDataType;
    distanceChartProps: ChartTypes.ChartInputDataType;
    percentile: number;
};

export interface WalkingAssessmentProps {
    tableProps: WalkingAssessmentTableType;
    diagnosisProps: Vo2MaxDiagnosisType;
    chartProps: WalkingSpeedAverageType;
    runningIntensityProps: RunningIntensityType;
    optimizedProgramProps: OptimizedProgramType;
}

///------------------------------Table------------------------------------

/// table 내의 등급
/// 6분 걷기 검사 평균치 등급 그래프에 사용되는 라벨 데이터
export enum gradeIndexEnum {
    Superior = 94,
    Excellent = 79,
    Good = 59,
    Fair = 39,
    Poor = 29,
    VeryPoor = 9,
}

interface AgeGroupVo2MaxTableType {
    vo2MaxArray: number[];
}

interface GenderVo2MaxPercentile {
    [ageGroup: string]: AgeGroupVo2MaxTableType;
}

interface Vo2MaxTableType {
    male: GenderVo2MaxPercentile;
    female: GenderVo2MaxPercentile;
}

export interface Vo2MaxAgeGroupTableType {
    min: number[];
    max: number[];
}

/// 20 ~ 70 대 남성 VO2Max 테이블
export const maleVo2MaxAgeGroupTable: Vo2MaxAgeGroupTableType = {
    min: [26.6, 26.6, 25.1, 21.3, 18.6, 17.9],
    max: [61.2, 58.3, 57, 54.3, 51.1, 49.7],
};

/// 20 ~ 70 대 여성 VO2Max 테이블
export const femaleVo2MaxAgeGroupTable: Vo2MaxAgeGroupTableType = {
    min: [22.6, 22.7, 20.8, 19.3, 18.1, 16.4],
    max: [55, 52.5, 51.1, 45.3, 42.4, 42.4],
};

export const vo2MaxTable: Vo2MaxTableType = {
    male: {
        '2': {
            vo2MaxArray: [
                26.6, 28.03, 29.45, 30.88, 32.3, 32.88, 33.46, 34.04, 34.62, 35.2, 35.5, 35.8, 36.1, 36.4, 36.7, 36.98, 37.26, 37.54, 37.82, 38.1, 38.38, 38.66, 38.94, 39.22, 39.5, 39.66, 39.82,
                39.98, 40.14, 40.3, 40.44, 40.58, 40.72, 40.86, 41, 41.24, 41.48, 41.72, 41.96, 42.2, 42.38, 42.56, 42.74, 42.92, 43.1, 43.26, 43.42, 43.58, 43.74, 43.9, 44.18, 44.46, 44.74, 45.02,
                45.3, 45.38, 45.46, 45.54, 45.62, 45.7, 45.92, 46.14, 46.36, 46.58, 46.8, 47.08, 47.36, 47.64, 47.92, 48.2, 48.4, 48.6, 48.8, 49, 49.2, 49.58, 49.96, 50.34, 50.72, 51.1, 51.38, 51.66,
                51.94, 52.22, 52.5, 52.8, 53.1, 53.4, 53.7, 54, 54.44, 54.88, 55.32, 55.76, 56.2, 57.45, 58.7, 59.95, 61.2,
            ],
        },
        '3': {
            vo2MaxArray: [
                26.6, 27.73, 28.85, 29.98, 31.1, 31.64, 32.18, 32.72, 33.26, 33.8, 34.08, 34.36, 34.64, 34.92, 35.2, 35.5, 35.8, 36.1, 36.4, 36.7, 36.88, 37.06, 37.24, 37.42, 37.6, 37.78, 37.96,
                38.14, 38.32, 38.5, 38.7, 38.9, 39.1, 39.3, 39.5, 39.8, 40.1, 40.4, 40.7, 41, 41.08, 41.16, 41.24, 41.32, 41.4, 41.6, 41.8, 42, 42.2, 42.4, 42.7, 43, 43.3, 43.6, 43.9, 44, 44.1, 44.2,
                44.3, 44.4, 44.58, 44.76, 44.94, 45.12, 45.3, 45.6, 45.9, 46.2, 46.5, 46.8, 46.94, 47.08, 47.22, 47.36, 47.5, 47.5, 47.5, 47.5, 47.5, 47.5, 48.14, 48.78, 49.42, 50.06, 50.7, 51.06,
                51.42, 51.78, 52.14, 52.5, 52.86, 53.22, 53.58, 53.94, 54.3, 55.3, 56.3, 57.3, 58.3,
            ],
        },
        '4': {
            vo2MaxArray: [
                25.1, 26.18, 27.25, 28.33, 29.4, 29.88, 30.36, 30.84, 31.32, 31.8, 32.12, 32.44, 32.76, 33.08, 33.4, 33.64, 33.88, 34.12, 34.36, 34.6, 34.82, 35.04, 35.26, 35.48, 35.7, 35.9, 36.1,
                36.3, 36.5, 36.7, 36.88, 37.06, 37.24, 37.42, 37.6, 37.76, 37.92, 38.08, 38.24, 38.4, 38.62, 38.84, 39.06, 39.28, 39.5, 39.68, 39.86, 40.04, 40.22, 40.4, 40.52, 40.64, 40.76, 40.88,
                41, 41.28, 41.56, 41.84, 42.12, 42.4, 42.7, 43, 43.3, 43.6, 43.9, 43.96, 44.02, 44.08, 44.14, 44.2, 44.44, 44.68, 44.92, 45.16, 45.4, 45.68, 45.96, 46.24, 46.52, 46.8, 47.14, 47.48,
                47.82, 48.16, 48.5, 49.02, 49.54, 50.06, 50.58, 51.1, 51.46, 51.82, 52.18, 52.54, 52.9, 53.93, 54.95, 55.98, 57,
            ],
        },
        '5': {
            vo2MaxArray: [
                21.3, 22.43, 23.55, 24.68, 25.8, 26.32, 26.84, 27.36, 27.88, 28.4, 28.68, 28.96, 29.24, 29.52, 29.8, 30.06, 30.32, 30.58, 30.84, 31.1, 31.34, 31.58, 31.82, 32.06, 32.3, 32.48, 32.66,
                32.84, 33.02, 33.2, 33.34, 33.48, 33.62, 33.76, 33.9, 34.16, 34.42, 34.68, 34.94, 35.2, 35.48, 35.76, 36.04, 36.32, 36.6, 36.62, 36.64, 36.66, 36.68, 36.7, 36.98, 37.26, 37.54, 37.82,
                38.1, 38.14, 38.18, 38.22, 38.26, 38.3, 38.54, 38.78, 39.02, 39.26, 39.5, 39.8, 40.1, 40.4, 40.7, 41, 41.16, 41.32, 41.48, 41.64, 41.8, 42.1, 42.4, 42.7, 43, 43.3, 43.56, 43.82, 44.08,
                44.34, 44.6, 45.04, 45.48, 45.92, 46.36, 46.8, 47.38, 47.96, 48.54, 49.12, 49.7, 50.85, 52, 53.15, 54.3,
            ],
        },
        '6': {
            vo2MaxArray: [
                18.6, 19.48, 20.35, 21.23, 22.1, 22.5, 22.9, 23.3, 23.7, 24.1, 24.46, 24.82, 25.18, 25.54, 25.9, 26.2, 26.5, 26.8, 27.1, 27.4, 27.66, 27.92, 28.18, 28.44, 28.7, 28.84, 28.98, 29.12,
                29.26, 29.4, 29.64, 29.88, 30.12, 30.36, 30.6, 30.76, 30.92, 31.08, 31.24, 31.4, 31.58, 31.76, 31.94, 32.12, 32.3, 32.46, 32.62, 32.78, 32.94, 33.1, 33.26, 33.42, 33.58, 33.74, 33.9,
                34.12, 34.34, 34.56, 34.78, 35, 35.18, 35.36, 35.54, 35.72, 35.9, 36.06, 36.22, 36.38, 36.54, 36.7, 36.98, 37.26, 37.54, 37.82, 38.1, 38.38, 38.66, 38.94, 39.22, 39.5, 39.8, 40.1,
                40.4, 40.7, 41, 41.44, 41.88, 42.32, 42.76, 43.2, 43.78, 44.36, 44.94, 45.52, 46.1, 47.35, 48.6, 49.85, 51.1,
            ],
        },
        '7': {
            vo2MaxArray: [
                17.9, 18.25, 18.6, 18.95, 19.3, 19.6, 19.9, 20.2, 20.5, 20.8, 21.08, 21.36, 21.64, 21.92, 22.2, 22.5, 22.8, 23.1, 23.4, 23.7, 23.98, 24.26, 24.54, 24.82, 25.1, 25.28, 25.46, 25.64,
                25.82, 26, 26.22, 26.44, 26.66, 26.88, 27.1, 27.28, 27.46, 27.64, 27.82, 28, 28.1, 28.2, 28.3, 28.4, 28.5, 28.68, 28.86, 29.04, 29.22, 29.4, 29.56, 29.72, 29.88, 30.04, 30.2, 30.34,
                30.48, 30.62, 30.76, 30.9, 31.18, 31.46, 31.74, 32.02, 32.3, 32.44, 32.58, 32.72, 32.86, 33, 33.28, 33.56, 33.84, 34.12, 34.4, 34.72, 35.04, 35.36, 35.68, 36, 36.42, 36.84, 37.26,
                37.68, 38.1, 38.38, 38.66, 38.94, 39.22, 39.5, 40.08, 40.66, 41.24, 41.82, 42.4, 44.23, 46.05, 47.88, 49.7,
            ],
        },
    },

    female: {
        '2': {
            vo2MaxArray: [
                22.6, 23.55, 24.5, 25.45, 26.4, 27, 27.6, 28.2, 28.8, 29.4, 29.62, 29.84, 30.06, 30.28, 30.5, 30.72, 30.94, 31.16, 31.38, 31.6, 31.76, 31.92, 32.08, 32.24, 32.4, 32.68, 32.96, 33.24,
                33.52, 33.8, 33.96, 34.12, 34.28, 34.44, 34.6, 34.78, 34.96, 35.14, 35.32, 35.5, 35.74, 35.98, 36.22, 36.46, 36.7, 36.84, 36.98, 37.12, 37.26, 37.4, 37.54, 37.68, 37.82, 37.96, 38.1,
                38.38, 38.66, 38.94, 39.22, 39.5, 39.72, 39.94, 40.16, 40.38, 40.6, 40.7, 40.8, 40.9, 41, 41.1, 41.56, 42.02, 42.48, 42.94, 43.4, 43.52, 43.64, 43.76, 43.88, 44, 44.26, 44.52, 44.78,
                45.04, 45.3, 45.74, 46.18, 46.62, 47.06, 47.5, 48.04, 48.58, 49.12, 49.66, 50.2, 51.4, 52.6, 53.8, 55,
            ],
        },
        '3': {
            vo2MaxArray: [
                22.7, 23.4, 24.1, 24.8, 25.5, 25.88, 26.26, 26.64, 27.02, 27.4, 27.7, 28, 28.3, 28.6, 28.9, 29.1, 29.3, 29.5, 29.7, 29.9, 30.1, 30.3, 30.5, 30.7, 30.9, 31.18, 31.46, 31.74, 32.02,
                32.3, 32.32, 32.34, 32.36, 32.38, 32.4, 32.68, 32.96, 33.24, 33.52, 33.8, 33.94, 34.08, 34.22, 34.36, 34.5, 34.64, 34.78, 34.92, 35.06, 35.2, 35.5, 35.8, 36.1, 36.4, 36.7, 36.7, 36.7,
                36.7, 36.7, 36.7, 36.98, 37.26, 37.54, 37.82, 38.1, 38.24, 38.38, 38.52, 38.66, 38.8, 39.1, 39.4, 39.7, 40, 40.3, 40.44, 40.58, 40.72, 40.86, 41, 41.3, 41.6, 41.9, 42.2, 42.5, 42.94,
                43.38, 43.82, 44.26, 44.7, 45.14, 45.58, 46.02, 46.46, 46.9, 48.3, 49.7, 51.1, 52.5,
            ],
        },
        '4': {
            vo2MaxArray: [
                20.8, 21.63, 22.45, 23.28, 24.1, 24.4, 24.7, 25, 25.3, 25.6, 25.82, 26.04, 26.26, 26.48, 26.7, 26.96, 27.22, 27.48, 27.74, 28, 28.28, 28.56, 28.84, 29.12, 29.4, 29.46, 29.52, 29.58,
                29.64, 29.7, 29.94, 30.18, 30.42, 30.66, 30.9, 31.04, 31.18, 31.32, 31.46, 31.6, 31.74, 31.88, 32.02, 32.16, 32.3, 32.5, 32.7, 32.9, 33.1, 33.3, 33.4, 33.5, 33.6, 33.7, 33.8, 34.06,
                34.32, 34.58, 34.84, 35.1, 35.2, 35.3, 35.4, 35.5, 35.6, 35.82, 36.04, 36.26, 36.48, 36.7, 36.98, 37.26, 37.54, 37.82, 38.1, 38.26, 38.42, 38.58, 38.74, 38.9, 39.12, 39.34, 39.56,
                39.78, 40, 40.48, 40.96, 41.44, 41.92, 42.4, 42.96, 43.52, 44.08, 44.64, 45.2, 46.68, 48.15, 49.63, 51.1,
            ],
        },
        '5': {
            vo2MaxArray: [
                19.3, 19.95, 20.6, 21.25, 21.9, 22.26, 22.62, 22.98, 23.34, 23.7, 23.88, 24.06, 24.24, 24.42, 24.6, 24.78, 24.96, 25.14, 25.32, 25.5, 25.72, 25.94, 26.16, 26.38, 26.6, 26.74, 26.88,
                27.02, 27.16, 27.3, 27.44, 27.58, 27.72, 27.86, 28, 28.14, 28.28, 28.42, 28.56, 28.7, 28.84, 28.98, 29.12, 29.26, 29.4, 29.56, 29.72, 29.88, 30.04, 30.2, 30.34, 30.48, 30.62, 30.76,
                30.9, 31, 31.1, 31.2, 31.3, 31.4, 31.58, 31.76, 31.94, 32.12, 32.3, 32.42, 32.54, 32.66, 32.78, 32.9, 33.14, 33.38, 33.62, 33.86, 34.1, 34.32, 34.54, 34.76, 34.98, 35.2, 35.5, 35.8,
                36.1, 36.4, 36.7, 36.98, 37.26, 37.54, 37.82, 38.1, 38.46, 38.82, 39.18, 39.54, 39.9, 41.25, 42.6, 43.95, 45.3,
            ],
        },
        '6': {
            vo2MaxArray: [
                18.1, 18.6, 19.1, 19.6, 20.1, 20.42, 20.74, 21.06, 21.38, 21.7, 21.92, 22.14, 22.36, 22.58, 22.8, 22.98, 23.16, 23.34, 23.52, 23.7, 23.8, 23.9, 24, 24.1, 24.2, 24.34, 24.48, 24.62,
                24.76, 24.9, 25, 25.1, 25.2, 25.3, 25.4, 25.64, 25.88, 26.12, 26.36, 26.6, 26.66, 26.72, 26.78, 26.84, 26.9, 27.02, 27.14, 27.26, 27.38, 27.5, 27.66, 27.82, 27.98, 28.14, 28.3, 28.46,
                28.62, 28.78, 28.94, 29.1, 29.16, 29.22, 29.28, 29.34, 29.4, 29.56, 29.72, 29.88, 30.04, 30.2, 30.36, 30.52, 30.68, 30.84, 31, 31.26, 31.52, 31.78, 32.04, 32.3, 32.44, 32.58, 32.72,
                32.86, 33, 33.32, 33.64, 33.96, 34.28, 34.6, 35.06, 35.52, 35.98, 36.44, 36.9, 38.28, 39.65, 41.03, 42.4,
            ],
        },
        '7': {
            vo2MaxArray: [
                16.4, 16.78, 17.15, 17.53, 17.9, 18.18, 18.46, 18.74, 19.02, 19.3, 19.6, 19.9, 20.2, 20.5, 20.8, 20.88, 20.96, 21.04, 21.12, 21.2, 21.34, 21.48, 21.62, 21.76, 21.9, 21.96, 22.02,
                22.08, 22.14, 22.2, 22.34, 22.48, 22.62, 22.76, 22.9, 23.08, 23.26, 23.44, 23.62, 23.8, 23.96, 24.12, 24.28, 24.44, 24.6, 24.7, 24.8, 24.9, 25, 25.1, 25.28, 25.46, 25.64, 25.82, 26,
                26.12, 26.24, 26.36, 26.48, 26.6, 26.8, 27, 27.2, 27.4, 27.6, 27.76, 27.92, 28.08, 28.24, 28.4, 28.6, 28.8, 29, 29.2, 29.4, 29.56, 29.72, 29.88, 30.04, 30.2, 30.56, 30.92, 31.28,
                31.64, 32, 32.3, 32.6, 32.9, 33.2, 33.5, 34.14, 34.78, 35.42, 36.06, 36.7, 38.13, 39.55, 40.98, 42.4,
            ],
        },
    },
};

///MS = Meter per Second
interface AgeGroupAvgWalkingSpeedCellType {
    MSspeed: number;
}

interface GenderAvgWalkingSpeed {
    [ageGroup: string]: AgeGroupAvgWalkingSpeedCellType;
}

interface AvgWalkingSpeedTableType {
    male: GenderAvgWalkingSpeed;
    female: GenderAvgWalkingSpeed;
}

export const avgWalkingSpeedTable: AvgWalkingSpeedTableType = {
    male: {
        '2': {
            MSspeed: 1.36,
        },
        '3': {
            MSspeed: 1.43,
        },
        '4': {
            MSspeed: 1.43,
        },
        '5': {
            MSspeed: 1.43,
        },
        '6': {
            MSspeed: 1.34,
        },
        '7': {
            MSspeed: 1.26,
        },
        '8': {
            MSspeed: 0.97,
        },
    },
    female: {
        '2': {
            MSspeed: 1.34,
        },
        '3': {
            MSspeed: 1.34,
        },
        '4': {
            MSspeed: 1.39,
        },
        '5': {
            MSspeed: 1.31,
        },
        '6': {
            MSspeed: 1.24,
        },
        '7': {
            MSspeed: 1.13,
        },
        '8': {
            MSspeed: 0.94,
        },
    },
};
