import { reportApi } from 'Api/Report/reportApi';
import BasicButton from 'components/Buttons/BasicButton';
import { MobilityAssessment } from 'components/MTEReportInput/MobilityAssessment';
import { MuscularEnduranceAssessment } from 'components/MTEReportInput/MuscularEnduranceAssessment';
import { RespiratoryAssessment } from 'components/MTEReportInput/RespiratoryAssessment';
import { SPPBAssessment } from 'components/MTEReportInput/SPPBAssessment';
import { TUGAssessment } from 'components/MTEReportInput/TUGAssessment';
import { pagePaths } from 'constants/path';
import { calculateAge } from 'functions/calculateAge';
import { removeDecimal } from 'functions/calculateFunctions';
import { getDistanceFromVo2Max, getVo2Max } from 'functions/calculateVO2max';
import { getVo2MaxBoundary } from 'functions/MTE/WalkingAssessmentFunctions';
import { reportInputValidate } from 'functions/validationFunctions';
import { createReportRequestType } from 'models/MTE/reportApiTypes';
import { AssessmentInputModel, MuscleInputEventType, RespiratoryAssessmentEventType, SPPBAssessmentEventType, SPPBAssessmentValueType } from 'models/MTE/ReportInput/assessmentInputTypes';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoadingStore } from 'store/useLoadingStore';
import styled from 'styled-components';
import { Common } from 'styles/Global/colorPrimitive';
import { Body2_Medium } from 'styles/Global/typography';
import { NUMBER_3_REGEX, NUMBER_4_DIGIT_1_REGEX } from 'utils/regex';

namespace MTEReportInputInitialState {
    export const InitialTUGAssessmentListState = [new AssessmentInputModel(undefined, 'TUG 시간', '초')];

    export const InitialCervicalMobilityFrontState = new AssessmentInputModel(undefined, '전측 (A)');
    export const InitialCervicalMobilityBackState = new AssessmentInputModel(undefined, '후측 (B)');
    export const InitialCervicalMobilityLeftState = new AssessmentInputModel(undefined, '좌측 (C)');
    export const InitialCervicalMobilityRightState = new AssessmentInputModel(undefined, '우측 (R)');

    export const InitialHipMobilityLeftState = new AssessmentInputModel(undefined, '좌측 (A)');
    export const InitialHipMobilityRightState = new AssessmentInputModel(undefined, '우측 (B)');

    export const InitialShoulderMobilityLeftState = new AssessmentInputModel(undefined, '좌측 (A)');
    export const InitialShoulderMobilityRightState = new AssessmentInputModel(undefined, '우측 (B)');

    export const InitialFunctionalMovementState = new AssessmentInputModel(undefined, '동측 (A)');

    export const InitialMuscularEnduranceAssessmentState = [new AssessmentInputModel(undefined, '앉아 일어선 횟수', '회')];

    export const InitialErrorMessage = {
        spo2Before: '',
        spo2After: '',
        hrBefore: '',
        hrAfter: '',
        walkDistance: '',
    };
}

const MTEReportInputBody = forwardRef((_, ref) => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [errMsgState, setErrMsgState] = useState(MTEReportInputInitialState.InitialErrorMessage);
    const [isGeneralPosture, setIsGeneralPosture] = useState<boolean | undefined>();
    const [isNonLinearPosture, setIsNonLinearPosture] = useState<boolean | undefined>();
    const [isLinearPosture, setIsLinearPosture] = useState<string | undefined>();
    const [isNoneLinearPostureDisabled, setIsNoneLinearPostureDisabled] = useState<boolean>(false);
    const [isLinearPostureDisabled, setIsLinearPostureDisabled] = useState<boolean>(false);
    const [isWalk, setIsWalk] = useState<boolean | undefined>();
    const [walkTime, setWalkTime] = useState<string | undefined>();
    const [isWalkDisabled, setIsWalkDisabled] = useState<boolean>(false);
    const [isStand, setIsStand] = useState<boolean | undefined>();
    const [standTime, setStandTime] = useState<string | undefined>();
    const [isStandDisabled, setIsStandDisabled] = useState<boolean>(false);

    const [TUGAssessementState, setTUGAssessmentState] = useState<string | undefined>();
    const [FRTState, setFRTState] = useState<string | undefined>();

    const [cervicalMobilityFrontState, setCervicalMobilityFrontState] = useState<AssessmentInputModel>(MTEReportInputInitialState.InitialCervicalMobilityFrontState);
    const [cervicalMobilityBackState, setCervicalMobilityBackState] = useState<AssessmentInputModel>(MTEReportInputInitialState.InitialCervicalMobilityBackState);
    const [cervicalMobilityLeftState, setCervicalMobilityLeftState] = useState<AssessmentInputModel>(MTEReportInputInitialState.InitialCervicalMobilityLeftState);
    const [cervicalMobilityRightState, setCervicalMobilityRightState] = useState<AssessmentInputModel>(MTEReportInputInitialState.InitialCervicalMobilityRightState);

    const [hipMobilityLeftState, setHipMobilityLeftState] = useState<AssessmentInputModel>(MTEReportInputInitialState.InitialHipMobilityLeftState);
    const [hipMobilityRightState, setHipMobilityRightState] = useState<AssessmentInputModel>(MTEReportInputInitialState.InitialHipMobilityRightState);

    const [shoulderMobilityLeftState, setShoulderMobilityLeftState] = useState<AssessmentInputModel>(MTEReportInputInitialState.InitialShoulderMobilityLeftState);
    const [shoulderMobilityRightState, setShoulderMobilityRightState] = useState<AssessmentInputModel>(MTEReportInputInitialState.InitialShoulderMobilityRightState);

    const [functionalMovementState, setFunctionalMovementState] = useState<AssessmentInputModel>(MTEReportInputInitialState.InitialFunctionalMovementState);

    ///muscle
    const [sitStandState, setSitStand] = useState<string | undefined>();
    const [coreState, setCoreState] = useState<string | undefined>();
    const [upperState, setUpperState] = useState<string | undefined>();

    /// respiratory
    const [walkDistanceState, setWalkDistanceState] = useState<string>('');
    const [vo2MaxState, setVO2MaxState] = useState<string>('');
    const [menualVO2MaxState, setMenualVO2MaxState] = useState<string>('');
    const [disabledMenualVO2Max, setDisabledMenualVO2Max] = useState<boolean>(false);
    const [walkDistanceDisabled, setWalkDistanceDisabled] = useState<boolean>(false);
    const [BDIBeforeState, setBDIBeforeState] = useState<string>('0');
    const [BDIAfterState, setBDIAfterState] = useState<string>('0');
    const [hrBeforeState, setHRBeforeState] = useState<string>('');
    const [hrAfterState, setHRAfterState] = useState<string>('');
    const [spo2BeforeState, setSPO2BeforeState] = useState<string>('');
    const [spo2AfterState, setSPO2AfterState] = useState<string>('');

    const [isReportButtonDisabled, setIsReportButtonDisabled] = useState<boolean>(true);

    const { setLoading } = useLoadingStore();

    /// 일반자세 입력 && 성공일 때 반일렬 자세 버튼 활성화
    useEffect(() => {
        if (isGeneralPosture) {
            setIsNoneLinearPostureDisabled(false);
        } else {
            setIsNoneLinearPostureDisabled(true);
            setIsNonLinearPosture(undefined);
        }
    }, [isGeneralPosture]);

    /// 일반자세 && 반일렬 자세 둘다 성공했을 때 일렬자세 입력 가능
    useEffect(() => {
        if (isGeneralPosture && isNonLinearPosture) {
            setIsLinearPostureDisabled(false);
        } else {
            setIsLinearPostureDisabled(true);
            setIsLinearPosture(undefined);
        }
    }, [isNonLinearPosture, isGeneralPosture]);

    /// 검사자 스스로 보행이 가능할 때
    useEffect(() => {
        if (isWalk) {
            setIsWalkDisabled(false);
        } else {
            setIsWalkDisabled(true);
            setWalkTime(undefined);
        }
    }, [isWalk]);

    /// 검사자가 팔을 사용하지 않고 앉아 일어서기 가능할 때
    useEffect(() => {
        if (isStand) {
            setIsStandDisabled(false);
        } else {
            setIsStandDisabled(true);
            setStandTime(undefined);
        }
    }, [isStand]);

    ///6분동안 걸은 거리 입력 시 VO2Max 계산
    useEffect(() => {
        if (!walkDistanceState) {
            setVO2MaxState('');
            setDisabledMenualVO2Max(false);
            return;
        }
        setDisabledMenualVO2Max(true);
    }, [walkDistanceState]);

    useEffect(() => {
        if (!menualVO2MaxState) {
            setWalkDistanceDisabled(false);
        }
        if (menualVO2MaxState) {
            setWalkDistanceDisabled(true);
            setErrMsgState((prevState) => ({
                ...prevState,
                walkDistance: '',
            }));
        }
    }, [menualVO2MaxState]);

    /// 요구사항에 맞는 데이터 충족 시 활성화
    useEffect(() => {
        setIsReportButtonDisabled(handleDisabledButton(state.reportType));
    }, [
        errMsgState,
        isGeneralPosture,
        isNonLinearPosture,
        isLinearPosture,
        isStand,
        isWalk,
        standTime,
        walkTime,
        TUGAssessementState,
        cervicalMobilityFrontState,
        cervicalMobilityBackState,
        cervicalMobilityLeftState,
        cervicalMobilityRightState,
        hipMobilityLeftState,
        hipMobilityRightState,
        shoulderMobilityLeftState,
        shoulderMobilityRightState,
        functionalMovementState,
        sitStandState,
        walkDistanceState,
        vo2MaxState,
        BDIBeforeState,
        BDIAfterState,
        menualVO2MaxState,
        FRTState,
        upperState,
        coreState,
        state.reportType,
    ]);

    // disabled validation
    const handleDisabledButton = (type: string) => {
        switch (type) {
            case 'sppbAndTug': {
                if (
                    isGeneralPosture === undefined ||
                    (isGeneralPosture && isNonLinearPosture === undefined) ||
                    (isGeneralPosture && isNonLinearPosture && !isLinearPosture) ||
                    isStand === undefined ||
                    (isStand && !standTime) ||
                    isWalk === undefined ||
                    (isWalk && !walkTime) ||
                    !TUGAssessementState
                ) {
                    return true;
                } else if (
                    (!isGeneralPosture ||
                        (isGeneralPosture && !isNonLinearPosture) ||
                        (!isGeneralPosture && isNonLinearPosture && isLinearPosture) ||
                        (isGeneralPosture && isNonLinearPosture && isLinearPosture)) &&
                    (!isStand || (isStand === true && standTime)) &&
                    (!isWalk || (isWalk === true && walkTime))
                ) {
                    return false;
                }
                break;
            }
            case 'gait': {
                if (
                    cervicalMobilityFrontState.score === undefined ||
                    cervicalMobilityBackState.score === undefined ||
                    cervicalMobilityLeftState.score === undefined ||
                    cervicalMobilityRightState.score === undefined ||
                    hipMobilityLeftState.score === undefined ||
                    hipMobilityRightState.score === undefined ||
                    shoulderMobilityLeftState.score === undefined ||
                    shoulderMobilityRightState.score === undefined ||
                    functionalMovementState.score === undefined ||
                    !FRTState
                ) {
                    return true;
                } else if (
                    cervicalMobilityFrontState.score &&
                    cervicalMobilityBackState.score &&
                    cervicalMobilityLeftState.score &&
                    cervicalMobilityRightState.score &&
                    hipMobilityLeftState.score &&
                    hipMobilityRightState.score &&
                    shoulderMobilityLeftState.score &&
                    shoulderMobilityRightState.score &&
                    functionalMovementState.score &&
                    FRTState
                ) {
                    return false;
                }
                break;
            }
            case 'muscle': {
                if (!sitStandState || !upperState || !coreState) {
                    return true;
                } else if (sitStandState && upperState && coreState) {
                    return false;
                }
                break;
            }
            case 'respiratory': {
                if (
                    !BDIBeforeState ||
                    !BDIAfterState ||
                    (!vo2MaxState && !menualVO2MaxState) ||
                    errMsgState.hrBefore ||
                    errMsgState.hrAfter ||
                    errMsgState.spo2Before ||
                    errMsgState.spo2After ||
                    errMsgState.walkDistance
                ) {
                    return true;
                } else if (
                    BDIBeforeState &&
                    BDIAfterState &&
                    ((vo2MaxState && !menualVO2MaxState) || (menualVO2MaxState && !vo2MaxState)) &&
                    !errMsgState.hrBefore &&
                    !errMsgState.hrAfter &&
                    !errMsgState.spo2Before &&
                    !errMsgState.spo2After &&
                    !errMsgState.walkDistance
                ) {
                    return false;
                }
                break;
            }
            default: {
                if (
                    isGeneralPosture === undefined ||
                    (isGeneralPosture && isNonLinearPosture === undefined) ||
                    (isGeneralPosture && isNonLinearPosture && !isLinearPosture) ||
                    isStand === undefined ||
                    (isStand && !standTime) ||
                    isWalk === undefined ||
                    (isWalk && !walkTime) ||
                    !TUGAssessementState ||
                    cervicalMobilityFrontState.score === undefined ||
                    cervicalMobilityBackState.score === undefined ||
                    cervicalMobilityLeftState.score === undefined ||
                    cervicalMobilityRightState.score === undefined ||
                    hipMobilityLeftState.score === undefined ||
                    hipMobilityRightState.score === undefined ||
                    shoulderMobilityLeftState.score === undefined ||
                    shoulderMobilityRightState.score === undefined ||
                    functionalMovementState.score === undefined ||
                    !FRTState ||
                    !sitStandState ||
                    !upperState ||
                    !coreState ||
                    !BDIBeforeState ||
                    !BDIAfterState ||
                    (!vo2MaxState && !menualVO2MaxState) ||
                    errMsgState.hrBefore ||
                    errMsgState.hrAfter ||
                    errMsgState.spo2Before ||
                    errMsgState.spo2After ||
                    errMsgState.walkDistance
                ) {
                    return true;
                }
                return false;
            }
        }
        return false;
    };

    // reset button
    const handleReset = useCallback(() => {
        setErrMsgState(MTEReportInputInitialState.InitialErrorMessage);
        setIsGeneralPosture(undefined);
        setIsNonLinearPosture(undefined);
        setIsLinearPosture(undefined);
        setIsStand(undefined);
        setIsWalk(undefined);
        setStandTime(undefined);
        setWalkTime(undefined);
        setTUGAssessmentState(undefined);
        setCervicalMobilityFrontState(MTEReportInputInitialState.InitialCervicalMobilityFrontState);
        setCervicalMobilityBackState(MTEReportInputInitialState.InitialCervicalMobilityBackState);
        setCervicalMobilityLeftState(MTEReportInputInitialState.InitialCervicalMobilityLeftState);
        setCervicalMobilityRightState(MTEReportInputInitialState.InitialCervicalMobilityRightState);
        setHipMobilityLeftState(MTEReportInputInitialState.InitialHipMobilityLeftState);
        setHipMobilityRightState(MTEReportInputInitialState.InitialHipMobilityRightState);
        setShoulderMobilityLeftState(MTEReportInputInitialState.InitialShoulderMobilityLeftState);
        setShoulderMobilityRightState(MTEReportInputInitialState.InitialShoulderMobilityRightState);
        setFunctionalMovementState(MTEReportInputInitialState.InitialFunctionalMovementState);
        setSitStand(undefined);
        setCoreState(undefined);
        setUpperState(undefined);
        setWalkDistanceState('');
        setVO2MaxState('');
        setBDIBeforeState('0');
        setBDIAfterState('0');
        setHRBeforeState('');
        setHRAfterState('');
        setSPO2BeforeState('');
        setSPO2AfterState('');
    }, []);

    /// 현재 심폐능력 검사만 validate가 있음 -> validateKey 고정으로 사용
    const validateCheckFunc = (type: string, value: string | undefined, onError?: () => void, onSuccess?: () => void) => {
        const validateKey = type;
        if (!value) {
            setErrMsgState((prevState) => ({
                ...prevState,
                [type]: '',
            }));
            onSuccess && onSuccess();
            return;
        }

        if (!reportInputValidate[validateKey](value)) {
            setErrMsgState((prevState) => ({
                ...prevState,
                [type]: '',
            }));
            onSuccess && onSuccess();
            return;
        }

        const result = reportInputValidate[validateKey](value, state);
        setErrMsgState((prevState) => ({
            ...prevState,
            [type]: result,
        }));

        if (onError && result) {
            onError();
        } else if (onSuccess && !result) {
            onSuccess();
        }
    };

    // api data divide
    const handleAPIData = (type: string) => {
        switch (type) {
            case 'sppbAndTug':
                return [
                    {
                        tug: {
                            tugTime: Number(TUGAssessementState!),
                        },
                        sppb: {
                            isGeneralPosture: isGeneralPosture ?? false,
                            isLinearPosture: isLinearPosture ? Number(isLinearPosture) : 0,
                            isNonLinearPosture: isNonLinearPosture ?? false,
                            isStand: isStand!,
                            isWalk: isWalk!,
                            standTime: standTime ? parseFloat(standTime) : 0,
                            walkTime: walkTime ? parseFloat(walkTime) : 0,
                        },
                    },
                ];
            case 'gait':
                return [
                    {
                        gait: {
                            neck: [
                                parseInt(cervicalMobilityFrontState.score!),
                                parseInt(cervicalMobilityBackState.score!),
                                parseInt(cervicalMobilityLeftState.score!),
                                parseInt(cervicalMobilityRightState.score!),
                            ],
                            hip: [parseInt(hipMobilityLeftState.score!), parseInt(hipMobilityRightState.score!)],
                            shoulder: [parseInt(shoulderMobilityLeftState.score!), parseInt(shoulderMobilityRightState.score!)],
                            functionalMotion: parseInt(functionalMovementState.score!),
                            functionalReach: parseInt(FRTState!),
                        },
                    },
                ];
            case 'muscle':
                return [
                    {
                        muscle: {
                            core: parseInt(coreState ?? '0'),
                            upper: removeDecimal(parseFloat(upperState ?? '0'), 1),
                            sitStand: parseInt(sitStandState ?? '0'),
                            muscleType: 0,
                        },
                    },
                ];
            case 'respiratory':
                return [
                    {
                        respiratory: {
                            bdiBefore: parseFloat(BDIBeforeState),
                            bdiAfter: parseFloat(BDIAfterState),
                            hrAfter: parseInt(hrAfterState) || 0,
                            hrBefore: parseInt(hrBeforeState) || 0,
                            spo2After: parseInt(spo2AfterState) || 0,
                            spo2Before: parseInt(spo2BeforeState) || 0,
                            walkDistance: walkDistanceState ? parseInt(walkDistanceState) : 0,
                            vo2Max: removeDecimal(parseFloat(menualVO2MaxState ? menualVO2MaxState : vo2MaxState), 1),
                        },
                    },
                ];
            default:
                return [
                    {
                        gait: {
                            neck: [
                                parseInt(cervicalMobilityFrontState.score!),
                                parseInt(cervicalMobilityBackState.score!),
                                parseInt(cervicalMobilityLeftState.score!),
                                parseInt(cervicalMobilityRightState.score!),
                            ],
                            hip: [parseInt(hipMobilityLeftState.score!), parseInt(hipMobilityRightState.score!)],
                            shoulder: [parseInt(shoulderMobilityLeftState.score!), parseInt(shoulderMobilityRightState.score!)],
                            functionalMotion: parseInt(functionalMovementState.score!),
                            functionalReach: parseInt(FRTState!),
                        },
                        muscle: {
                            core: parseInt(coreState ?? '0'),
                            upper: removeDecimal(parseFloat(upperState ?? '0'), 1),
                            sitStand: parseInt(sitStandState ?? '0'),
                            muscleType: 0,
                        },
                        tug: {
                            tugTime: Number(TUGAssessementState!),
                        },
                        sppb: {
                            isGeneralPosture: isGeneralPosture ?? false,
                            isLinearPosture: isLinearPosture ? Number(isLinearPosture) : 0,
                            isNonLinearPosture: isNonLinearPosture ?? false,
                            isStand: isStand!,
                            isWalk: isWalk!,
                            standTime: standTime ? parseFloat(standTime) : 0,
                            walkTime: walkTime ? parseFloat(walkTime) : 0,
                        },
                        respiratory: {
                            bdiBefore: parseFloat(BDIBeforeState),
                            bdiAfter: parseFloat(BDIAfterState),
                            hrAfter: parseInt(hrAfterState) || 0,
                            hrBefore: parseInt(hrBeforeState) || 0,
                            spo2After: parseInt(spo2AfterState) || 0,
                            spo2Before: parseInt(spo2BeforeState) || 0,
                            walkDistance: walkDistanceState ? parseInt(walkDistanceState) : 0,
                            vo2Max: removeDecimal(parseFloat(menualVO2MaxState ? menualVO2MaxState : vo2MaxState), 1),
                        },
                    },
                ];
        }
    };

    // 보고서 만들기 버튼
    const clickConfirmButton = async () => {
        if (!state) return;
        setIsReportButtonDisabled(true);
        setLoading(true);
        const reportData: createReportRequestType = {
            name: state.name,
            birthday: state.birthday,
            email: state.email,
            gender: state.gender,
            height: state.height,
            managerName: state.managerName,
            memo: state.memo,
            mobile: state.mobile,
            weight: state.weight,
            data: handleAPIData(state.reportType),
        };

        const response = await reportApi.createReport(reportData);

        setLoading(false);
        setIsReportButtonDisabled(false);

        if (response) {
            navigate(pagePaths.report, {
                state: {
                    response,
                    // reportType: state.reportType,
                },
            });
        }
    };

    const handleTUGAssessmentChange = useCallback((value: string | undefined) => {
        setTUGAssessmentState(value);
    }, []);

    const handleMuscleChange = useCallback((key: MuscleInputEventType, value: string | undefined) => {
        switch (key) {
            case 'core':
                setCoreState(value);
                break;
            case 'upper':
                setUpperState(value);
                break;
            case 'sitStand':
                setSitStand(value);
                break;
        }
    }, []);

    const handleRespiratoryAssessmentChange = useCallback((key: RespiratoryAssessmentEventType, value: string) => {
        switch (key) {
            case 'BDIBefore':
                setBDIBeforeState(value);
                break;
            case 'BDIAfter':
                setBDIAfterState(value);
                break;
            case 'hrBefore':
                if (!NUMBER_3_REGEX.test(value)) return;
                setHRBeforeState(value);
                validateCheckFunc(key, value);
                break;
            case 'hrAfter':
                if (!NUMBER_3_REGEX.test(value)) return;
                setHRAfterState(value);
                validateCheckFunc(key, value);
                break;
            case 'spo2Before':
                if (!NUMBER_3_REGEX.test(value)) return;
                setSPO2BeforeState(value);
                validateCheckFunc(key, value);
                break;
            case 'spo2After':
                if (!NUMBER_3_REGEX.test(value)) return;
                setSPO2AfterState(value);
                validateCheckFunc(key, value);
                break;
            case 'menualVo2Max':
                if (!NUMBER_4_DIGIT_1_REGEX.test(value)) return;
                setMenualVO2MaxState(value);
                break;
            case 'walkDistance':
                if (!NUMBER_4_DIGIT_1_REGEX.test(value)) return;
                setWalkDistanceState(value);
                validateCheckFunc(key, value);
                break;
        }
    }, []);

    const handleSPPBAssessmentChange = (key: SPPBAssessmentEventType, value: SPPBAssessmentValueType) => {
        switch (key) {
            case 'isGeneralPosture':
                setIsGeneralPosture(value as boolean);
                break;
            case 'isNonLinearPosture':
                setIsNonLinearPosture(value as boolean);
                break;
            case 'isLinearPosture':
                setIsLinearPosture(value as string);
                break;
            case 'isWalk':
                setIsWalk(value as boolean);
                break;
            case 'walkTime':
                setWalkTime(value as string);
                break;
            case 'isStand':
                setIsStand(value as boolean);
                break;
            case 'standTime':
                setStandTime(value as string);
        }
    };
    const calculatevo2max = () => {
        if (state.birthday !== undefined && state.gender !== undefined && state.height !== undefined && state.weight !== undefined && walkDistanceState)
            setVO2MaxState(
                removeDecimal(
                    getVo2Max({
                        age: parseInt(calculateAge(state.birthday).justAgeString.replace('세', '')),
                        walkdistance: parseFloat(walkDistanceState),
                        gender: state.gender,
                        height: state.height,
                        weight: state.weight,
                    })
                ).toString()
            );
    };

    const handleBlur = (type: RespiratoryAssessmentEventType, value: string) => {
        if (!value) return;

        switch (type) {
            case 'walkDistance':
                validateCheckFunc(
                    type,
                    value,
                    () => setVO2MaxState(''),
                    () => calculatevo2max()
                );
                setWalkDistanceState(parseInt(value).toString());

                break;
            case 'hrBefore':
                setHRBeforeState(parseInt(value).toString());
                break;
            case 'hrAfter':
                setHRAfterState(parseInt(value).toString());
                break;
            case 'spo2Before':
                setSPO2BeforeState(parseInt(value).toString());
                break;
            case 'spo2After':
                setSPO2AfterState(parseInt(value).toString());
                break;
            case 'menualVo2Max':
                setMenualVO2MaxState(parseFloat(value).toString());
                break;
        }
    };

    useImperativeHandle(ref, () => ({
        onReset: handleReset,
    }));

    const onHistoryBack = () => {
        navigate(-1);
    };

    // randering divide
    const divideRander = (type: string) => {
        switch (type) {
            case 'sppbAndTug':
                return (
                    <>
                        <SPPBAssessment
                            isGeneralPosture={isGeneralPosture}
                            isLinearPosture={isLinearPosture}
                            isNonLinearPosture={isNonLinearPosture}
                            isStand={isStand}
                            isWalk={isWalk}
                            standTime={standTime}
                            walkTime={walkTime}
                            onEvent={handleSPPBAssessmentChange}
                            isLinearPostureDisabled={isLinearPostureDisabled}
                            isWalkDisabled={isWalkDisabled}
                            isStandDisabled={isStandDisabled}
                            isNoneLinearPostureDisabled={isNoneLinearPostureDisabled}
                        />
                        <TUGAssessment tugTime={TUGAssessementState} onChangeTugTime={handleTUGAssessmentChange} />
                    </>
                );
            case 'gait':
                return (
                    <>
                        <MobilityAssessment
                            FRT={FRTState}
                            onChangeInput={setFRTState}
                            assessmentInputList={[
                                {
                                    subjectName: '경추 가동성',
                                    subjectDescription: '흉추와 견갑대의 안정성을 필요로 하며 경추의 시상면과 횡단면에서의 가동 범위를 확인하는 검사',
                                    assessmentInputList: [cervicalMobilityFrontState, cervicalMobilityBackState, cervicalMobilityLeftState, cervicalMobilityRightState],
                                    onChange: [
                                        (value) => {
                                            setCervicalMobilityFrontState(new AssessmentInputModel(value, cervicalMobilityFrontState.name));
                                        },
                                        (value) => {
                                            setCervicalMobilityBackState(new AssessmentInputModel(value, cervicalMobilityBackState.name));
                                        },
                                        (value) => {
                                            setCervicalMobilityLeftState(new AssessmentInputModel(value, cervicalMobilityLeftState.name));
                                        },
                                        (value) => {
                                            setCervicalMobilityRightState(new AssessmentInputModel(value, cervicalMobilityRightState.name));
                                        },
                                    ],
                                },
                                {
                                    subjectName: '고관절 가동성',
                                    subjectDescription: '들어 올리는 다리 고관절의 가동성, 골반 컨트롤 능력, 코어의 안정성, 반대쪽 고관절의 신전 능력을 확인하는 검사',
                                    assessmentInputList: [hipMobilityLeftState, hipMobilityRightState],
                                    onChange: [
                                        (value) => {
                                            setHipMobilityLeftState(new AssessmentInputModel(value, hipMobilityLeftState.name));
                                        },
                                        (value) => {
                                            setHipMobilityRightState(new AssessmentInputModel(value, hipMobilityRightState.name));
                                        },
                                    ],
                                },
                                {
                                    subjectName: '어깨 가동성',
                                    subjectDescription: '양 어깨의 내/외 회전, 신전과 굴곡, 내/외전을 평가하여 어깨와 흉추의 가동 범위를 확인하는 검사',
                                    assessmentInputList: [shoulderMobilityLeftState, shoulderMobilityRightState],
                                    onChange: [
                                        (value) => {
                                            setShoulderMobilityLeftState(new AssessmentInputModel(value, shoulderMobilityLeftState.name));
                                        },
                                        (value) => {
                                            setShoulderMobilityRightState(new AssessmentInputModel(value, shoulderMobilityRightState.name));
                                        },
                                    ],
                                },
                                {
                                    subjectName: '기능성 움직임',
                                    subjectDescription: '코어와 흉추, 신전, 어깨의 기능을 필요로 하며, 고관절과 무릎, 발목 양측의 가동성, 안정성을 확인하는 검사',
                                    assessmentInputList: [functionalMovementState],
                                    onChange: [
                                        (value) => {
                                            setFunctionalMovementState(new AssessmentInputModel(value, functionalMovementState.name));
                                        },
                                    ],
                                },
                            ]}
                        />
                    </>
                );
            case 'muscle':
                return (
                    <>
                        <MuscularEnduranceAssessment sitStand={sitStandState} core={coreState} upper={upperState} onEvent={handleMuscleChange} />
                    </>
                );
            case 'respiratory':
                return (
                    <>
                        <RespiratoryAssessment
                            BDIAfter={BDIAfterState}
                            BDIBefore={BDIBeforeState}
                            hrAfter={hrAfterState}
                            hrBefore={hrBeforeState}
                            spo2After={spo2AfterState}
                            spo2Before={spo2BeforeState}
                            vo2Max={vo2MaxState}
                            walkDistance={walkDistanceState}
                            onEvent={handleRespiratoryAssessmentChange}
                            errorMsgs={errMsgState}
                            onBlur={handleBlur}
                            disabledMenualVo2Max={disabledMenualVO2Max}
                            disabledWalkDistance={walkDistanceDisabled}
                            menualVo2Max={menualVO2MaxState}
                        />
                    </>
                );
            default:
                return (
                    <>
                        <SPPBAssessment
                            isGeneralPosture={isGeneralPosture}
                            isLinearPosture={isLinearPosture}
                            isNonLinearPosture={isNonLinearPosture}
                            isStand={isStand}
                            isWalk={isWalk}
                            standTime={standTime}
                            walkTime={walkTime}
                            onEvent={handleSPPBAssessmentChange}
                            isLinearPostureDisabled={isLinearPostureDisabled}
                            isWalkDisabled={isWalkDisabled}
                            isStandDisabled={isStandDisabled}
                            isNoneLinearPostureDisabled={isNoneLinearPostureDisabled}
                        />
                        <TUGAssessment tugTime={TUGAssessementState} onChangeTugTime={handleTUGAssessmentChange} />
                        <MobilityAssessment
                            FRT={FRTState}
                            onChangeInput={setFRTState}
                            assessmentInputList={[
                                {
                                    subjectName: '경추 가동성',
                                    subjectDescription: '흉추와 견갑대의 안정성을 필요로 하며 경추의 시상면과 횡단면에서의 가동 범위를 확인하는 검사',
                                    assessmentInputList: [cervicalMobilityFrontState, cervicalMobilityBackState, cervicalMobilityLeftState, cervicalMobilityRightState],
                                    onChange: [
                                        (value) => {
                                            setCervicalMobilityFrontState(new AssessmentInputModel(value, cervicalMobilityFrontState.name));
                                        },
                                        (value) => {
                                            setCervicalMobilityBackState(new AssessmentInputModel(value, cervicalMobilityBackState.name));
                                        },
                                        (value) => {
                                            setCervicalMobilityLeftState(new AssessmentInputModel(value, cervicalMobilityLeftState.name));
                                        },
                                        (value) => {
                                            setCervicalMobilityRightState(new AssessmentInputModel(value, cervicalMobilityRightState.name));
                                        },
                                    ],
                                },
                                {
                                    subjectName: '고관절 가동성',
                                    subjectDescription: '들어 올리는 다리 고관절의 가동성, 골반 컨트롤 능력, 코어의 안정성, 반대쪽 고관절의 신전 능력을 확인하는 검사',
                                    assessmentInputList: [hipMobilityLeftState, hipMobilityRightState],
                                    onChange: [
                                        (value) => {
                                            setHipMobilityLeftState(new AssessmentInputModel(value, hipMobilityLeftState.name));
                                        },
                                        (value) => {
                                            setHipMobilityRightState(new AssessmentInputModel(value, hipMobilityRightState.name));
                                        },
                                    ],
                                },
                                {
                                    subjectName: '어깨 가동성',
                                    subjectDescription: '양 어깨의 내/외 회전, 신전과 굴곡, 내/외전을 평가하여 어깨와 흉추의 가동 범위를 확인하는 검사',
                                    assessmentInputList: [shoulderMobilityLeftState, shoulderMobilityRightState],
                                    onChange: [
                                        (value) => {
                                            setShoulderMobilityLeftState(new AssessmentInputModel(value, shoulderMobilityLeftState.name));
                                        },
                                        (value) => {
                                            setShoulderMobilityRightState(new AssessmentInputModel(value, shoulderMobilityRightState.name));
                                        },
                                    ],
                                },
                                {
                                    subjectName: '기능성 움직임',
                                    subjectDescription: '코어와 흉추, 신전, 어깨의 기능을 필요로 하며, 고관절과 무릎, 발목 양측의 가동성, 안정성을 확인하는 검사',
                                    assessmentInputList: [functionalMovementState],
                                    onChange: [
                                        (value) => {
                                            setFunctionalMovementState(new AssessmentInputModel(value, functionalMovementState.name));
                                        },
                                    ],
                                },
                            ]}
                        />
                        <MuscularEnduranceAssessment sitStand={sitStandState} core={coreState} upper={upperState} onEvent={handleMuscleChange} />
                        <RespiratoryAssessment
                            BDIAfter={BDIAfterState}
                            BDIBefore={BDIBeforeState}
                            hrAfter={hrAfterState}
                            hrBefore={hrBeforeState}
                            spo2After={spo2AfterState}
                            spo2Before={spo2BeforeState}
                            vo2Max={vo2MaxState}
                            walkDistance={walkDistanceState}
                            onEvent={handleRespiratoryAssessmentChange}
                            errorMsgs={errMsgState}
                            onBlur={handleBlur}
                            disabledMenualVo2Max={disabledMenualVO2Max}
                            disabledWalkDistance={walkDistanceDisabled}
                            menualVo2Max={menualVO2MaxState}
                        />
                    </>
                );
        }
    };

    return (
        <Layout>
            {divideRander(state.reportType)}
            <BottomButtonContainer>
                <BasicButton $width='200px' $height='56px' $type='fill' onClick={onHistoryBack}>
                    <ButtonText>이전 화면</ButtonText>
                </BasicButton>
                <BasicButton $width='200px' $height='56px' $type='fill' onClick={clickConfirmButton} disabled={isReportButtonDisabled}>
                    <ButtonText>보고서 만들기</ButtonText>
                </BasicButton>
            </BottomButtonContainer>
        </Layout>
    );
});

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
    padding: 0 0 100px 0;
`;

const BottomButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 54px;
    gap: 148px;
`;

const ButtonText = styled(Body2_Medium)`
    color: ${Common.color_white};
`;

export default MTEReportInputBody;
