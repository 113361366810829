type FindApproximationIndexFunc = (array: number[], target: number) => number;

/// 소수점 제거 함수
export const removeDecimal = (target: number, point: number = 2): number => {
    const pow = Math.pow(10, point);
    return Math.floor(target * pow) / pow;
};


/// 소수점 반올림 함수
export const roundDecimal = (target: number, point: number = 2): number => {
    const pow = Math.pow(10, point);
    return Math.round(target * pow) / pow;
};

/// 배열 상 근사치 인덱스 반환 함수
/// 정렬 되어있어야 사용 가능
export const findApproximationIndex: FindApproximationIndexFunc = (array, target) => {
    let closestIndex = 0;
    let closestDiff = Math.abs(array[0] - target);
    for (let i = 1; i < array.length; i++) {
        const diff = Math.abs(array[i] - target);
        if (diff < closestDiff) {
            closestDiff = diff;
            closestIndex = i;
        }
    }
    return closestIndex;
};
