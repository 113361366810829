import { calculateAge } from 'functions/calculateAge';
import { findApproximationIndex, removeDecimal, roundDecimal } from 'functions/calculateFunctions';
import { getDistanceFromVo2Max } from 'functions/calculateVO2max';
import { IMDataResponse } from 'models/Common/commonApiTypes';
import { IMReportData } from 'models/Common/commonTypes';
import {
    AgeGroup,
    AgeToAgeGroupFunc,
    femaleVo2MaxAgeGroupTable,
    GetAvgVo2MaxFromAgeFunc,
    GetMETFunc,
    GetPercentileFunc,
    gradeIndexEnum,
    KH6minuteSpeedFunc,
    maleVo2MaxAgeGroupTable,
    OptimizedProgramType,
    PercentileToGradeFunc,
    RunningIntensityType,
    Vo2MaxAgeGroupTableType,
    Vo2MaxDiagnosisType,
    vo2MaxTable,
    WalkingAssessmentTableType,
    WalkingSpeedAverageType,
} from 'models/MTE/walkingAssessmentTypes';

// 심폐지구력 진단 쪽 구하는 함수
export const Vo2MaxDiagnosisTypeFunc = (reportData: IMDataResponse<IMReportData>, setState: Vo2MaxDiagnosisType) => {
    const respiratory = reportData.data[0].respiratory;
    const age = parseInt(calculateAge(reportData.birthday.slice(2)).justAgeString.replace('세', ''));
    const vo2MaxPercentile = getPercentile(respiratory.vo2Max, age, reportData.gender);
    const totalScoreResult = percentileToGrade(vo2MaxPercentile);

    setState.memo = reportData.memo;
    setState.cardiorespiratoryAge = getCardiorespiratoryAge(respiratory.vo2Max, reportData.gender);
    setState.totalScoreResult = totalScoreResult;
    setState.vo2MaxPercentile = vo2MaxPercentile;
    setState.vo2Max = respiratory.vo2Max;
};

// 심폐지구력 평균치 비교 쪽 함수
export const WalkingSpeedAverageFunc = (reportData: IMDataResponse<IMReportData>, setstate: WalkingSpeedAverageType) => {
    const respiratory = reportData.data[0].respiratory;
    const gender = reportData.gender;
    const age = parseInt(calculateAge(reportData.birthday.slice(2)).justAgeString.replace('세', ''));
    const walkDistance = respiratory.walkDistance
        ? respiratory.walkDistance
        : getDistanceFromVo2Max({ age: age, gender: gender, height: reportData.height, vo2Max: respiratory.vo2Max, weight: reportData.weight });
    const vo2MaxAvg = getAvgVo2MaxFromAge(age, gender);
    const distanceAvg = removeDecimal(getDistanceFromVo2Max({ age: age, gender: gender, height: reportData.height, vo2Max: vo2MaxAvg, weight: reportData.weight }));
    const walkingSpeed = getKHSpeedFrom6minute(walkDistance);
    const avgWalkingSpeed = removeDecimal((distanceAvg / 360) * 3.6, 1);
    const percentile = getPercentile(respiratory.vo2Max, age, gender);
    const distanceOverallData = ageGroupVo2MaxOverallData(age, gender, (data) =>
        removeDecimal(getDistanceFromVo2Max({ age: age, gender: gender, height: reportData.height, vo2Max: data, weight: reportData.weight }))
    );
    const speedOverallData = distanceOverallData.map((data) => removeDecimal((data / 360) * 3.6));

    setstate.percentile = percentile;
    setstate.speedChartProps = {
        averageData: avgWalkingSpeed,
        percentile: percentile,
        overallData: speedOverallData,
        inputData: walkingSpeed,
    };
    setstate.distanceChartProps = {
        averageData: distanceAvg,
        percentile: percentile,
        overallData: distanceOverallData,
        inputData: walkDistance,
    };
};

// 6분 걷기 검사 셀 구하는 함수
export const WalkingAssessmentTableFunc = (reportData: IMDataResponse<IMReportData>, setstate: WalkingAssessmentTableType) => {
    const respiratory = reportData.data[0].respiratory;
    const gender = reportData.gender;
    const age = parseInt(calculateAge(reportData.birthday.slice(2)).justAgeString.replace('세', ''));
    const mets = getMET(respiratory.vo2Max);
    const walkDistance = respiratory.walkDistance
        ? respiratory.walkDistance
        : getDistanceFromVo2Max({ age: age, gender: gender, height: reportData.height, vo2Max: respiratory.vo2Max, weight: reportData.weight });
    const speed = getKHSpeedFrom6minute(walkDistance);
    const vo2Average = getAvgVo2MaxFromAge(age, reportData.gender);

    setstate.distance = walkDistance;
    setstate.bidAfter = respiratory.bdiAfter;
    setstate.bidBefore = respiratory.bdiBefore;
    setstate.hrAfter = respiratory.hrAfter;
    setstate.hrBefore = respiratory.hrBefore;
    setstate.mets = mets;
    setstate.speed = speed;
    setstate.spo2After = respiratory.spo2After;
    setstate.spo2Before = respiratory.spo2Before;
    setstate.vo2Average = vo2Average;
    setstate.vo2Max = respiratory.vo2Max;
};

export const RunningIntensityFunc = (reportData: IMDataResponse<IMReportData>, setState: RunningIntensityType) => {
    const respiratory = reportData.data[0].respiratory;
    const burnFatExKH = getTrademillBurnFatExerciseKH(respiratory.vo2Max);
    const aerobicExKH = getTrademillAerobicExerciseKH(respiratory.vo2Max);
    const highIntensityExKH = getTrademillHighIntensityExerciseKH(respiratory.vo2Max);
    const circulatorySafezoneGuide = getSafezoneGuide({ vo2Max: respiratory.vo2Max, safezoneType: 'circulatory' });
    const metabolicSafezoneGuide = getSafezoneGuide({ vo2Max: respiratory.vo2Max, safezoneType: 'metabolic' });

    setState.circulatorySafezoneGuide.min = circulatorySafezoneGuide.min;
    setState.circulatorySafezoneGuide.max = circulatorySafezoneGuide.max;
    setState.metabolicSafezoneGuide.min = metabolicSafezoneGuide.min;
    setState.metabolicSafezoneGuide.max = metabolicSafezoneGuide.max;
    setState.outdoorGoalHigh.kilometer = roundDecimal(getTrademillHighIntensityExerciseKH(respiratory.vo2Max + 3.5) / 4, 1);
    setState.outdoorGoalHigh.minute = 15;
    setState.outdoorGoalLow.kilometer = roundDecimal(burnFatExKH / 2, 1);
    setState.outdoorGoalLow.minute = 30;
    setState.outdoorGoalMid.kilometer = roundDecimal(aerobicExKH / 3, 1);
    setState.outdoorGoalMid.minute = 20;
    setState.targetAerobicExercise.kcalPerMinute = getTrademillExerciseKcalPerMinute({ kiometerPerHour: aerobicExKH, weight: reportData.weight });
    setState.targetAerobicExercise.kilometerPerHour = aerobicExKH;
    setState.targetBurnFatExercise.kcalPerMinute = getTrademillExerciseKcalPerMinute({ kiometerPerHour: burnFatExKH, weight: reportData.weight });
    setState.targetBurnFatExercise.kilometerPerHour = burnFatExKH;
    setState.targetHighIntensityExercise.kcalPerMinute = getTrademillExerciseKcalPerMinute({ kiometerPerHour: highIntensityExKH, weight: reportData.weight });
    setState.targetHighIntensityExercise.kilometerPerHour = highIntensityExKH;
};

export const OptimizedProgramFunc = (reportData: IMDataResponse<IMReportData>, setState: OptimizedProgramType) => {
    const respiratory = reportData.data[0].respiratory;
    const burnFatExKH = getTrademillBurnFatExerciseKH(respiratory.vo2Max);
    const highIntensityExKH = getTrademillHighIntensityExerciseKH(respiratory.vo2Max);
    const repetition = getRunningIntensityRepetitions({
        burnFatEx: getTrademillExerciseKcalPerMinute({ kiometerPerHour: burnFatExKH, weight: reportData.weight }),
        highIntensityEx: getTrademillExerciseKcalPerMinute({ kiometerPerHour: highIntensityExKH, weight: reportData.weight }),
        weight: reportData.weight,
    });
    const age = parseInt(calculateAge(reportData.birthday.slice(2)).justAgeString.replace('세', ''));
    const zone1HR = getGoalHR({ beforeHR: respiratory.hrBefore, age: age, zoneType: 'zoneOne' });
    const zone2HR = getGoalHR({ beforeHR: respiratory.hrBefore, age: age, zoneType: 'zoneTwo' });

    setState.recoveryRun = burnFatExKH;
    setState.heartRateZone.zone1.min = zone1HR.min;
    setState.heartRateZone.zone1.max = zone1HR.max;
    setState.heartRateZone.zone1.kcalPerMinute = getZoneKcalPerMinute({ weight: reportData.weight, vo2Max: respiratory.vo2Max, zoneType: 'zoneOne' });
    setState.heartRateZone.zone2.min = zone2HR.min;
    setState.heartRateZone.zone2.max = zone2HR.max;
    setState.heartRateZone.zone2.kcalPerMinute = getZoneKcalPerMinute({ weight: reportData.weight, vo2Max: respiratory.vo2Max, zoneType: 'zoneTwo' });
    setState.highIntensityRun.burnFat.kilometerPerHour = burnFatExKH;
    setState.highIntensityRun.burnFat.kcalPerMinute = getTrademillExerciseKcalPerMinute({ kiometerPerHour: burnFatExKH, weight: reportData.weight });
    setState.highIntensityRun.highIntensity.kilometerPerHour = highIntensityExKH;
    setState.highIntensityRun.highIntensity.kcalPerMinute = getTrademillExerciseKcalPerMinute({ kiometerPerHour: highIntensityExKH, weight: reportData.weight });
    setState.highIntensityRun.repetition.min = repetition.minRep;
    setState.highIntensityRun.repetition.max = repetition.maxRep;
};

/// 해당 나이 그룹의 VO2Max 백분위 값 반환(1 ~ 99)
const findAgeGroupVo2MaxArray = (ageGroup: string, gender: 'male' | 'female'): number[] => {
    switch (gender) {
        case 'male':
            return vo2MaxTable.male[ageGroup].vo2MaxArray;
        case 'female':
            return vo2MaxTable.female[ageGroup].vo2MaxArray;
    }
};

/// 해당 나이에 맞게 VO2Max 배열을 계산 후 반환
export const findAgeVo2MaxArray = (age: number, gender: 'male' | 'female'): number[] => {
    const ageGroup = ageToAgeGroup(age);
    const ageArray = findAgeGroupVo2MaxArray(ageGroup, gender);

    if (age % 10 === 5) {
        return ageArray;
    }

    const nearAge = age % 10 > 5 ? Math.floor(age / 10) * 10 + 15 : Math.floor(age / 10) * 10 - 5;

    const nearAgeGroup = ageToAgeGroup(nearAge);
    const nearAgeArray = findAgeGroupVo2MaxArray(nearAgeGroup, gender);

    const targetAgeArray: number[] = [];

    ageArray.forEach((data, index) => {
        let result = 0;

        if (age > nearAge) {
            result = nearAgeArray[index] + (age - nearAge) * ((data - nearAgeArray[index]) / 10);
        } else {
            result = nearAgeArray[index] + (age - nearAge) * ((data - nearAgeArray[index]) / -10);
        }
        targetAgeArray.push(roundDecimal(result));
    });

    return targetAgeArray;
};

const ageToAgeGroup: AgeToAgeGroupFunc = (age) => {
    if (age < 20) return '2';
    if (age > 79) return '7';
    const tenDigitNumber = age.toString().slice(0, 1);
    const result = AgeGroup.find((ageGroup) => ageGroup === tenDigitNumber);
    return result ? result : '7';
};

// 백분위 값에 따른 등급 반환 함수
export const percentileToGrade: PercentileToGradeFunc = (percentile) => {
    if (percentile >= 95) return 'Superior';
    if (percentile >= 80) return 'Excellent';
    if (percentile >= 60) return 'Good';
    if (percentile >= 40) return 'Fair';
    if (percentile >= 20) return 'Poor';
    return 'Very Poor';
};

/// 백분위 값 === 배열 인덱스 + 1
/// ex:) 50% === 인덱스 + 1
// 백분위 값 반환 함수
export const getPercentile: GetPercentileFunc = (vo2Max, age, gender) => {
    // const minimumVo2Max = gender === 'male' ? 27.94 : 24.02; ///85세의 vo2Max;
    // const maximumVo2Max = gender === 'male' ? 48.66 : 42.08; /// 15세의 vo2Max;

    const ageGroup = ageToAgeGroup(age);
    const ageArray = findAgeGroupVo2MaxArray(ageGroup, gender);

    if (age % 10 === 5) {
        return findApproximationIndex(ageArray, vo2Max) + 1;
    }

    const nearAge = age % 10 > 5 ? Math.floor(age / 10) * 10 + 15 : Math.floor(age / 10) * 10 - 5;

    const nearAgeGroup = ageToAgeGroup(nearAge);
    const nearAgeArray = findAgeGroupVo2MaxArray(nearAgeGroup, gender);

    const targetAgeArray: number[] = [];

    ageArray.forEach((data, index) => {
        let result = 0;

        if (age > nearAge) {
            result = nearAgeArray[index] + (age - nearAge) * ((data - nearAgeArray[index]) / 10);
        } else {
            result = nearAgeArray[index] + (age - nearAge) * ((data - nearAgeArray[index]) / -10);
        }
        targetAgeArray.push(roundDecimal(result));
    });

    return findApproximationIndex(targetAgeArray, vo2Max) + 1;
};

// 나이에 따른 VO2Max 평균치 반환 함수
export const getAvgVo2MaxFromAge: GetAvgVo2MaxFromAgeFunc = (age, gender) => {
    const ageGroup = ageToAgeGroup(age);
    const avgIndex = 49;
    const array = findAgeGroupVo2MaxArray(ageGroup, gender);
    return array[avgIndex];
};

// VO2Max 값에 따른 MET 값 반환 함수
export const getMET: GetMETFunc = (vo2Max) => {
    const metConstant = 3.5;
    const met = vo2Max / metConstant;
    return removeDecimal(met, 1);
};

///KH = Kilometer per Hour
export const getKHSpeedFrom6minute: KH6minuteSpeedFunc = (distance) => {
    const sixMinute = 360;
    const speed = (distance / sixMinute) * 3.6;
    return removeDecimal(speed, 1);
};

// 선형 보간법을 사용하여 나이 그룹에 따른 VO2Max 세그먼트를 계산하는 함수
const calculateVo2MaxSegment = (vo2MaxX: number, vo2MaxY: number, ageX: number, ageY: number): number[] => {
    const vo2MaxSegment: number[] = [];
    for (let age = ageX; age <= ageY; age++) {
        const vo2Max = vo2MaxX + ((vo2MaxY - vo2MaxX) / (ageY - ageX)) * (age - ageX);
        vo2MaxSegment.push(vo2Max);
    }
    return vo2MaxSegment;
};

//심폐나이 계산 함수
/// 테이블의 각 나이대별 60퍼센트(원래 평균 50 퍼센트인데 대표님 요청으로 60퍼센트로 변경) 값과 vo2Max를 비교하여 가장 가까운 나이대를 찾고
/// 이전 나잇대 또는 이후 나잇대와의 gap을 찾아서 10분할 후 가까운 값의 인덱스를 찾아서 심폐나이를 계산
export const getCardiorespiratoryAge = (vo2Max: number, gender: 'male' | 'female'): number => {
    /// 각 나잇대별 60퍼센트
    const nearVo2MaxAgeGroupArray: number[] = [];

    ///심폐나이
    let cardiorespiratoryAge = 0;

    /// vo2Max 테이블
    const table = gender === 'male' ? vo2MaxTable.male : vo2MaxTable.female;

    const minimumVo2Max = gender === 'male' ? 26.8 : 24.1; ///85세의 vo2Max;
    const maximumVo2Max = gender === 'male' ? 47 : 42.3; /// 15세의 vo2Max;

    nearVo2MaxAgeGroupArray.push(maximumVo2Max);

    /// 각 나이대 별 60퍼센트 값 추출
    for (const index in AgeGroup) {
        nearVo2MaxAgeGroupArray.push(table[AgeGroup[index]].vo2MaxArray[59]);
    }

    nearVo2MaxAgeGroupArray.push(minimumVo2Max);

    /// 20 ~ 70대 루프
    for (let i = 0; i < nearVo2MaxAgeGroupArray.length; i++) {
        const calculateAgeFunc = (group: number, digitIndex: number): number => {
            return group * 10 + (digitIndex + 5);
        };

        const calculateVo2MaxFunc = (min: number, max: number, idx: number) => {
            const gap = roundDecimal((max - min) / 10);

            const vo2MaxGapArray: number[] = [min];

            Array.from({ length: 9 }, (_, index) => {
                vo2MaxGapArray.push(removeDecimal(min + gap * (index + 1)));
                return null;
            });

            vo2MaxGapArray.push(max);

            return calculateAgeFunc(idx, vo2MaxGapArray.length - findApproximationIndex(vo2MaxGapArray, vo2Max) - 1);
        };

        if (vo2Max >= maximumVo2Max) {
            cardiorespiratoryAge = 20;
            break;
        }

        if (vo2Max <= minimumVo2Max) {
            cardiorespiratoryAge = 79;
            break;
        }

        if (nearVo2MaxAgeGroupArray[i] === vo2Max) {
            cardiorespiratoryAge = calculateAgeFunc(i + 1, 0);
            break;
        }

        /// 현재 vo2Max가 25세 vo2Max보다 좋을 경우
        if (nearVo2MaxAgeGroupArray[i] < vo2Max && i === 0) {
            cardiorespiratoryAge = calculateVo2MaxFunc(maximumVo2Max, nearVo2MaxAgeGroupArray[i], i);
            break;
        }

        if (nearVo2MaxAgeGroupArray[i] < vo2Max) {
            cardiorespiratoryAge = calculateVo2MaxFunc(nearVo2MaxAgeGroupArray[i], nearVo2MaxAgeGroupArray[i - 1], i);
            break;
        }

        /// 현재 vo2Max가 75세 vo2Max보다 안좋을 경우
        if (nearVo2MaxAgeGroupArray[i] > vo2Max && i === nearVo2MaxAgeGroupArray.length - 1) {
            return 79;
        }
    }

    if (cardiorespiratoryAge > 79) {
        cardiorespiratoryAge = 79;
    } else if (cardiorespiratoryAge < 20) {
        cardiorespiratoryAge = 20;
    }

    return cardiorespiratoryAge;
};

/// 해당나이의 Vo2Max 전체 분포 데이터 추출
export const ageGroupVo2MaxOverallData = <T>(age: number, gender: 'male' | 'female', convertCallback: (data: number) => T): T[] => {
    const vo2MaxOverallGroup = gradeIndexEnum;
    const vo2MaxArray = findAgeVo2MaxArray(age, gender);
    return vo2MaxArray
        .filter((_, index) => {
            switch (index) {
                case vo2MaxOverallGroup.Superior:
                case vo2MaxOverallGroup.Excellent:
                case vo2MaxOverallGroup.Good:
                case vo2MaxOverallGroup.Fair:
                case vo2MaxOverallGroup.Poor:
                case vo2MaxOverallGroup.VeryPoor:
                    return true;
                default:
                    return false;
            }
        })
        .map((data) => convertCallback(data));
};

const trademillConstant = {
    burnFat: 0.5,
    aerobic: 0.8,
};

/// 질환 타입에 따라서 분기
const safezoneConstant = {
    metabolic: {
        min: 0.4,
        max: 0.6,
    },
    circulatory: {
        min: 0.4,
        max: 0.8,
    },
};

export type SafezoneConstant = typeof safezoneConstant;

///KH = Kilometer per Hour

/// 트레드 밀 지방 연소 목표 계산 함수
export const getTrademillBurnFatExerciseKH = (vo2Max: number): number => {
    const burnFatEx = ((vo2Max - 3.5) * trademillConstant.burnFat + 3.5 - 3.5) / 3.35;
    return roundDecimal(burnFatEx, 1);
};

/// 트레드 밀 유산소 목표 계산 함수
export const getTrademillAerobicExerciseKH = (vo2Max: number): number => {
    const aerobicEx = ((vo2Max - 3.5) * trademillConstant.aerobic + 3.5 - 3.5) / 3.35;
    return roundDecimal(aerobicEx, 1);
};

/// 트레드 밀 고강도 목표 계산 함수
export const getTrademillHighIntensityExerciseKH = (vo2Max: number): number => {
    const highIntensityEx = (vo2Max - 3.5) / 3.35;
    return roundDecimal(highIntensityEx, 1);
};

/// 트레드 밀 운동량 분당 소모 칼로리 계산 함수
export const getTrademillExerciseKcalPerMinute = ({ kiometerPerHour, weight }: { kiometerPerHour: number; weight: number }): number => {
    return roundDecimal((kiometerPerHour * 3.35 * weight) / 200, 1);
};

/// 질병 별 안전구간 가이드 계산 함수(질환자 가이드 테이블용)
export const getSafezoneGuide = ({ vo2Max, safezoneType }: { vo2Max: number; safezoneType: keyof SafezoneConstant }): { min: number; max: number } => {
    return {
        min: roundDecimal(((vo2Max - 3.5) * safezoneConstant[safezoneType].min + 3.5 - 3.5) / 3.35, 1),
        max: roundDecimal(((vo2Max - 3.5) * safezoneConstant[safezoneType].max + 3.5 - 3.5) / 3.35, 1),
    };
};

/// 고강도 인터벌 러닝 rep 계산 함수
export const getRunningIntensityRepetitions = ({ burnFatEx, highIntensityEx, weight }: { burnFatEx: number; highIntensityEx: number; weight: number }): { minRep: number; maxRep: number } => {
    const minRepConstant = 400 / 70;
    const maxRepConstant = 500 / 70;
    return {
        minRep: roundDecimal((weight * minRepConstant) / (burnFatEx * 2 + highIntensityEx * 2), 1),
        maxRep: roundDecimal((weight * maxRepConstant) / (burnFatEx * 2 + highIntensityEx * 2), 1),
    };
};

/// ----------------- 심박 수 zone 계산 --------------------
/// heart rate -> bpm단위 사용

/// zone1에 있는 추천 심박 수
const zoneOneConstant = {
    min: 0.5,
    max: 0.6,
};

/// zone2에 있는 추천 심박 수
const zoneTwoConstant = {
    min: 0.6,
    max: 0.7,
};

/// 최대 심박수 상수
const maxHeartRate = 220;

/// zone1, zone2의 min, max 사이 값
const zoneConstant = {
    zoneOne: 0.55,
    zoneTwo: 0.65,
};

export type ZoneConstant = typeof zoneConstant;

/// zone1, zone2의 kcal/min 계산 함수
export const getZoneKcalPerMinute = ({ weight, vo2Max, zoneType }: { weight: number; vo2Max: number; zoneType: keyof ZoneConstant }): number => {
    return roundDecimal(((vo2Max - 3.5) * zoneConstant[zoneType] * weight) / 200, 1);
};

/// zone1, zone2의 목표 심박 수 계산 함수
export const getGoalHR = ({ beforeHR, age, zoneType }: { beforeHR: number; age: number; zoneType: keyof ZoneConstant }): { min: number; max: number } => {
    const recommededHR = maxHeartRate - age - beforeHR;
    let min = 0;
    let max = 0;
    switch (zoneType) {
        case 'zoneOne':
            min = zoneOneConstant.min;
            max = zoneOneConstant.max;
            break;
        case 'zoneTwo':
            min = zoneTwoConstant.min;
            max = zoneTwoConstant.max;
            break;
    }
    return {
        min: Math.round(beforeHR + recommededHR * min),
        max: Math.round(beforeHR + recommededHR * max),
    };
};

export interface Vo2MaxBoundary {
    min: number;
    max: number;
}

export const getVo2MaxBoundary = (age: number, gender: string): Vo2MaxBoundary => {
    if (gender !== 'male' && gender !== 'female') {
        throw new Error("Gender must be 'male' or 'female'");
    }

    const minAge = 25;
    const maxAge = 75;
    let vo2MaxTable: Vo2MaxAgeGroupTableType;

    if (gender === 'male') {
        vo2MaxTable = maleVo2MaxAgeGroupTable;
    } else {
        vo2MaxTable = femaleVo2MaxAgeGroupTable;
    }

    if (age < minAge) age = minAge;
    if (age > maxAge) age = maxAge;

    // 해당 나이의 테이블 인덱스
    const index = Math.floor(age / 10) - 2;
    // 해당 나이의 테이블 인덱스에서 가장 가까운 인덱스 임의 설정
    let nearIndex = index;

    let max = 0;
    let min = 0;

    // 음수냐 양수냐 0이냐를 판단해서 해당 나이대의 위, 아래 설정.
    const ageSegment = (age % 10) - 5;

    if (ageSegment === 0) {
        return { min: vo2MaxTable.min[index], max: vo2MaxTable.max[index] };
    }

    // 양수면 5보다 큰 수이니 nearIndex를 증가시키고 음수면 5보다 작은 수이니 nearIndex를 감소시킨다. 그러나 값은 역으로 설정해줘야함.
    const isAgeRemainderGreaterThanFive = ageSegment > 0;

    /// 나이그룹의 5세 미만일 경우(ex: 23세) && 인덱스가 테이블의 길이를 넘지 않는 경우
    if (isAgeRemainderGreaterThanFive && index < vo2MaxTable.max.length - 1) {
        nearIndex++;

        min = vo2MaxTable.min[index] - ((vo2MaxTable.min[index] - vo2MaxTable.min[nearIndex]) / 10) * ageSegment;
        max = vo2MaxTable.max[index] - ((vo2MaxTable.max[index] - vo2MaxTable.max[nearIndex]) / 10) * ageSegment;

        /// 나이그룹의 5세 초과일 경우(ex: 28세) && 인덱스가 0보다 큰 경우
    } else if (!isAgeRemainderGreaterThanFive && index > 0) {
        nearIndex--;

        min = vo2MaxTable.min[index] + ((vo2MaxTable.min[nearIndex] - vo2MaxTable.min[index]) / 10) * -ageSegment;
        max = vo2MaxTable.max[index] + ((vo2MaxTable.max[nearIndex] - vo2MaxTable.max[index]) / 10) * -ageSegment;
    } else {
        min = vo2MaxTable.min[index];
        max = vo2MaxTable.max[index];
    }

    return { min, max };
};
