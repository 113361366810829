import { List } from '@mui/material';
import { reportApi } from 'Api/Report/reportApi';
import { ListWrapper, TableCell, HeaderWrapper as TableHeader } from 'components/Common/PaginationForms';
import { changeMobileForm } from 'functions/changeMobileForm';
import { IMDataResponse } from 'models/Common/commonApiTypes';
import { IMReportData } from 'models/Common/commonTypes';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLoadingStore } from 'store/useLoadingStore';
import styled from 'styled-components';
import { Display1_Bold } from 'styles/Global/typography';

// birthDay, email, gender, height, managername, memo, mobile, name, weight
const ReportListPage = () => {
    const { loading, setLoading } = useLoadingStore();

    const titleMock = ['이름', '생년월일', '휴대전화', '이메일', '성별', '키', '몸무게', '담당자', '메모'];

    const [allReportData, setAllReportData] = useState<IMDataResponse<IMReportData>[]>();
    useEffect(() => {
        fetchData();
    }, []);

    // report All data 불러오기
    const fetchData = async () => {
        const response = await reportApi.getAllReport();
        return setAllReportData(response);
    };

    return (
        <>
            <HeaderLayout>
                <HeaderTitle>Report Result List</HeaderTitle>
            </HeaderLayout>
            <Layout>
                <TableHeader>
                    {titleMock.map((title, idx) => {
                        return (
                            <TableCell key={idx} $width={`${100 / titleMock.length}`} $idx={idx} $type={'header'} $justifyContent={'center'}>
                                {title}
                            </TableCell>
                        );
                    })}
                </TableHeader>
                {allReportData?.map((data, idx) => {
                    return (
                        <ListWrapper key={idx}>
                            <TableCell $width={`${100 / titleMock.length}`} $idx={idx} $type={'name'} $justifyContent={'center'}>
                                {data.name}
                            </TableCell>
                            <TableCell $width={`${100 / titleMock.length}`} $idx={idx} $type={'body'} $justifyContent={'center'}>
                                {moment(data.birthday, 'YYYYMMDD').format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell $width={`${100 / titleMock.length}`} $idx={idx} $type={'body'} $justifyContent={'center'}>
                                {changeMobileForm(data.mobile)}
                            </TableCell>
                            <TableCell $width={`${100 / titleMock.length}`} $idx={idx} $type={'body'} $justifyContent={'center'}>
                                {data.email}
                            </TableCell>
                            <TableCell $width={`${100 / titleMock.length}`} $idx={idx} $type={'body'} $justifyContent={'center'}>
                                {data.gender === 'male' ? '남성' : '여성'}
                            </TableCell>
                            <TableCell $width={`${100 / titleMock.length}`} $idx={idx} $type={'body'} $justifyContent={'center'}>
                                {data.height}cm
                            </TableCell>
                            <TableCell $width={`${100 / titleMock.length}`} $idx={idx} $type={'body'} $justifyContent={'center'}>
                                {data.weight}kg
                            </TableCell>
                            <TableCell $width={`${100 / titleMock.length}`} $idx={idx} $type={'body'} $justifyContent={'center'}>
                                {data.managerName} 선생님
                            </TableCell>
                            <TableCell $width={`${100 / titleMock.length}`} $idx={idx} $type={'body'}>
                                {data.memo}
                            </TableCell>
                        </ListWrapper>
                    );
                })}
            </Layout>
        </>
    );
};

export default ReportListPage;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 4px;
`;

const HeaderLayout = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const HeaderTitle = styled(Display1_Bold)``;
