import { Assessment } from 'components/Common/Assessment';
import { BDIAssessmentValues, RespiratoryAssessmentInputProps } from 'models/MTE/ReportInput/assessmentInputTypes';
import React from 'react';
import styled from 'styled-components';
import {
    AssessmentName,
    AsssessmentFlexInputWrapper,
    AsssessmentInputWrapper,
    AsssessmentMultiInputWrapper,
    ContainerBodyWrapper,
    ContainerHeaderWrapper,
    ContainerLayout,
    InnerDivider,
    Spacer,
    SubTitle,
    Title,
    WidthSpacer,
} from 'styles/ReportStyles/MTEReportInputStyles';

export const RespiratoryAssessment = React.memo(
    ({
        BDIAfter,
        BDIBefore,
        hrAfter,
        hrBefore,
        onEvent,
        spo2After,
        spo2Before,
        vo2Max,
        menualVo2Max,
        walkDistance,
        errorMsgs,
        onBlur,
        disabledMenualVo2Max,
        disabledWalkDistance,
    }: RespiratoryAssessmentInputProps) => {
        return (
            <ContainerLayout>
                <ContainerHeaderWrapper>
                    <Title>• 심폐능력검사</Title>
                    <WidthSpacer $width={18} />
                    <SubTitle>6분 걷기 검사 (6분간 걸은 거리 입력하면 최대산소 섭취량 자동계산/ 혹은 최대산소 섭취량 수동입력)</SubTitle>
                </ContainerHeaderWrapper>
                <InnerDivider />
                <ContainerBodyWrapper>
                    <AsssessmentMultiInputWrapper>
                        <AsssessmentInputWrapper>
                            <AssessmentName> * 6분동안 걸은 거리</AssessmentName>
                            <Assessment.WithOutSubLabelInputWithLabel
                                value={walkDistance ?? ''}
                                unit='m'
                                label=''
                                disabled={disabledWalkDistance}
                                id='walkDistance'
                                onBlur={() => {
                                    onBlur('walkDistance', walkDistance);
                                }}
                                errorMsg={errorMsgs.walkDistance}
                                onChange={(e) => {
                                    onEvent('walkDistance', e.currentTarget.value);
                                }}
                            />
                        </AsssessmentInputWrapper>
                        <Spacer />
                        <AsssessmentInputWrapper>
                            <AssessmentName>최대산소 섭취량</AssessmentName>
                            {vo2Max && <SubTitle>{vo2Max}</SubTitle>}
                            <SubTitle>VO2MAX</SubTitle>
                        </AsssessmentInputWrapper>
                    </AsssessmentMultiInputWrapper>
                </ContainerBodyWrapper>
                <InnerDivider $height={0.5} />
                <ContainerBodyWrapper>
                    <AsssessmentInputWrapper>
                        <AssessmentName> * 최대산소 섭취량 수동 입력</AssessmentName>
                        <Assessment.Input
                            unit='VO2MAX'
                            disabled={disabledMenualVo2Max}
                            onBlur={() => {
                                onBlur('menualVo2Max', menualVo2Max);
                            }}
                            onChange={(e) => {
                                onEvent('menualVo2Max', e.currentTarget.value);
                            }}
                            value={menualVo2Max}
                        />
                    </AsssessmentInputWrapper>
                </ContainerBodyWrapper>
                <InnerDivider />
                <ContainerBodyWrapper>
                    <AssessmentName>호흡곤란 지수 BDI</AssessmentName>
                    <AsssessmentInputWrapper>
                        <Assessment.DropdownWithTitle
                            $children={BDIAssessmentValues}
                            $onSelect={(value) => {
                                onEvent('BDIBefore', value.score);
                            }}
                            $selectedChild={BDIAssessmentValues.find((value) => value.score === BDIBefore)}
                            $title='운동 전'
                        />
                        <Assessment.DropdownWithTitle
                            $children={BDIAssessmentValues}
                            $onSelect={(value) => {
                                onEvent('BDIAfter', value.score);
                            }}
                            $selectedChild={BDIAssessmentValues.find((value) => value.score === BDIAfter)}
                            $title='운동 후'
                        />
                    </AsssessmentInputWrapper>
                </ContainerBodyWrapper>
                <InnerDivider />
                <InnerNameWrapper>
                    <AssessmentName>심박수 HR</AssessmentName>
                    <AssessmentName>산소포화도 SpO2</AssessmentName>
                </InnerNameWrapper>
                <ContainerBodyWrapper>
                    <AsssessmentMultiInputWrapper>
                        <AsssessmentFlexInputWrapper>
                            <AsssessmentInputWrapper>
                                <Assessment.WithOutSubLabelInputWithLabel
                                    value={hrBefore ?? ''}
                                    label='운동 전'
                                    placeholder='1~250 이내로 입력'
                                    unit='HR'
                                    errorMsg={errorMsgs.hrBefore}
                                    id='spo2After'
                                    onBlur={() => {
                                        onBlur('hrBefore', hrBefore);
                                    }}
                                    onChange={(e) => {
                                        onEvent('hrBefore', e.currentTarget.value);
                                    }}
                                />
                                <Assessment.WithOutSubLabelInputWithLabel
                                    value={hrAfter ?? ''}
                                    label='운동 후'
                                    placeholder='1~250 이내로 입력'
                                    errorMsg={errorMsgs.hrAfter}
                                    unit='HR'
                                    id='spo2After'
                                    onBlur={() => {
                                        onBlur('hrAfter', hrAfter);
                                    }}
                                    onChange={(e) => {
                                        onEvent('hrAfter', e.currentTarget.value);
                                    }}
                                />
                            </AsssessmentInputWrapper>
                        </AsssessmentFlexInputWrapper>
                        <AsssessmentFlexInputWrapper>
                            <AsssessmentInputWrapper>
                                <Assessment.WithOutSubLabelInputWithLabel
                                    value={spo2Before ?? ''}
                                    label='운동 전'
                                    placeholder='1~100 이내로 입력'
                                    unit='%'
                                    id='spo2Before'
                                    errorMsg={errorMsgs.spo2Before}
                                    onBlur={() => {
                                        onBlur('spo2Before', spo2Before);
                                    }}
                                    onChange={(e) => {
                                        onEvent('spo2Before', e.currentTarget.value);
                                    }}
                                />
                                <Assessment.WithOutSubLabelInputWithLabel
                                    value={spo2After ?? ''}
                                    label='운동 후'
                                    placeholder='1~100 이내로 입력'
                                    unit='%'
                                    id='spo2After'
                                    onBlur={() => {
                                        onBlur('spo2After', spo2After);
                                    }}
                                    errorMsg={errorMsgs.spo2After}
                                    onChange={(e) => {
                                        onEvent('spo2After', e.currentTarget.value);
                                    }}
                                />
                            </AsssessmentInputWrapper>
                        </AsssessmentFlexInputWrapper>
                    </AsssessmentMultiInputWrapper>
                </ContainerBodyWrapper>
            </ContainerLayout>
        );
    }
);

const InnerNameWrapper = styled.div`
    display: flex;
    padding: 40px 20px 10px;
    border-bottom: 1px solid #6f6f6f;
    & > * {
        flex: 1;
    }
`;
